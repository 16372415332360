import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/startseite'
  },

  {
    path: '/startseite',
    name: 'Startseite',
    component: () => import(
      /* webpackChunkName: "Startseite" */
      '@/views/Startseite.vue')

  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
