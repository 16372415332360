<template>

    <div class="logo">
        <!-- <h1>{{ msg }}</h1> -->
        <img :src="item.url" />
        <p>
        </p>
    </div>
</template>

<script>
export default {
    name: 'CLogo',
    data: function() {
        return {
            item: { url: 'https://drcv.de/storage/Header/drcv_logo.jpg', title: '01' }
        }
    }
}
</script>

<style scoped>
.logo {
    width: 126px;
}
img {
    height: auto;
    max-width: 100%;
}
</style>