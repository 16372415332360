import Vue from 'vue'
import App from './App.vue'
import router from './router'

import '@mdi/font/css/materialdesignicons.css'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

// import VuePictureSwipe from 'vue-picture-swipe'
// Vue.component('vue-picture-swipe', VuePictureSwipe)

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
