<template>
  <v-tabs color="white"
          show-arrows
          id="mainmenu"
          slider-color="black"
          v-model="model.activeItem">

    <v-tab v-for="(item, i) in items"
           :key="i"
           :href="item.link">
      {{item.name}}
    </v-tab>

  </v-tabs>
</template>

<script>
export default {
    name: 'CMainMenu',
    props: {
        menuItems: {
            type: Array,
            required: true
        },
        startItem: {
            type: String,
            required: true
        }
    },
    data: function() {
        return {
            model: {
                activeItem: null
            }
        }
    },
    computed: {
        items: function() {
            return this.menuItems
        }
    },
    mounted() {
        this.model.activeItem = this.startItem
    }
}
</script>
<style scoped>
a.v-tab {
    border: solid;
    border-width: 1px;
    border-color: rgb(219, 219, 219);
    text-decoration: none;
    text-transform: none;
    color: black;
    font-size: 16px;
    margin: 0;
    padding: 0 7px;
}
a.v-tab.v-tab--active {
    color: white;
    background-color: #b71c1c;
}
</style>