import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VContent } from 'vuetify/lib/components/VContent';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c('div',{staticClass:"unsupported-browser"},[_c(VSnackbar,{attrs:{"color":_vm.snackbar.color,"timeout":_vm.snackbar.timeout,"top":"","multi-line":""},model:{value:(_vm.snackbar.visible),callback:function ($$v) {_vm.$set(_vm.snackbar, "visible", $$v)},expression:"snackbar.visible"}},[_c('p',{staticClass:"text-xs-center",domProps:{"innerHTML":_vm._s(_vm.snackbar.text)}}),_c(VBtn,{attrs:{"color":"white","flat":""},on:{"click":function($event){_vm.snackbar.visible = false}}},[_vm._v(" X ")])],1)],1),_c(VContent,[_c(VContainer,{staticClass:"pb-0",attrs:{"text-center":""}},[_c(VRow,[_c(VCol,{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('c-main-menu',{attrs:{"menuItems":_vm.menuItems,"startItem":"Startseite"}})],1)],1),_c(VRow,[_c(VCol,{staticClass:"pb-0",attrs:{"cols":"12","sm":"3","md":"2","lg":"1"}},[_c('c-logo')],1),_c(VSpacer),_c(VCol,{staticClass:"pb-0",attrs:{"cols":"12","sm":"9","md":"10","lg":"10"}},[_c('div',{staticClass:"appTitle"},[_vm._v("DEUTSCHER RALLYE CROSS VERBAND E.V.")])])],1)],1),_c('router-view')],1),_c('c-footer'),_c('c-piwik')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }