<template>
  <v-app>

    <div class="unsupported-browser">
      <v-snackbar v-model="snackbar.visible"
                  :color="snackbar.color"
                  :timeout="snackbar.timeout"
                  top
                  multi-line>
        <p class="text-xs-center"
           v-html="snackbar.text"></p>
        <v-btn color="white"
               flat
               @click="snackbar.visible = false">
          X
        </v-btn>
      </v-snackbar>
    </div>

    <v-content>

      <v-container text-center
                   class="pb-0">

        <v-row>
          <v-col cols='12'
                 class="pb-0">
            <c-main-menu :menuItems="menuItems"
                         startItem="Startseite"></c-main-menu>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols='12'
                 sm='3'
                 md='2'
                 lg='1'
                 class="pb-0">
            <c-logo></c-logo>
          </v-col>
          <v-spacer>
          </v-spacer>
          <v-col cols='12'
                 sm='9'
                 md='10'
                 lg='10'
                 class="pb-0">
            <div class="appTitle">DEUTSCHER RALLYE CROSS VERBAND E.V.</div>
          </v-col>
        </v-row>

      </v-container>

      <router-view>
      </router-view>

    </v-content>

    <c-footer>
    </c-footer>

    <c-piwik>
    </c-piwik>

  </v-app>
</template>

<script>
import CLogo from '@/components/CLogo.vue'
import CMainMenu from '@/components/CMainMenu.vue'
import CFooter from '@/components/CFooter.vue'
import CPiwik from '@/components/CPiwik.vue'
import axios from 'axios'
import { loadProgressBar } from 'axios-progress-bar'
import 'axios-progress-bar/dist/nprogress.css'

export default {
    name: 'DRCVApp',
    components: {
        CLogo,
        CMainMenu,
        CFooter,
        CPiwik,
    },
    computed: {
        // menuItems() {
        //     return this.$store.state.menuItems
        // }
    },
    data() {
        return {
            menuItems: [],
            snackbar: {
                text:
                    'Bitte beachtet, dass der Internet Explorer zukünftig nicht mehr unterstützt wird. Daher wird geraten auf ' +
                    '<a href="https://www.microsoft.com/de-de/windows/microsoft-edge">Microsoft Edge</a>, ' +
                    '<a href="https://chrome.google.com">Google Chrome</a> oder ' +
                    '<a href="https://mozilla.org/firefox/">Mozilla Firefox</a> zu wechseln.</p>',
                visible: false,
                color: 'error',
                timeout: 0,
            },
        }
    },
    methods: {
        loadMenu: async function () {
            let res
            try {
                res = await axios.get('https://api.drcv.de/daten.php?type=menu')
                this.menuItems = res.data.items
            } catch (err) {
                console.log(err)
            }
        },
    },
    async mounted() {
        if (!!window.MSInputMethodContext && !!document.documentMode) {
            this.snackbar.visible = true
        }
        loadProgressBar({ minimum: 0.3 })

        await this.loadMenu()
    },
}
</script>

<style scoped>
.appTitle {
    font-size: 38px;
    color: gray;

    font-weight: bold;
    padding: 15px;
}
</style>
