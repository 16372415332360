<template>
<div></div>
</template>

<script>
export default {
    name: 'CPiwik',
    mounted() {
        var _paq = _paq || []
        _paq.push(['setDocumentTitle', document.domain + '/' + document.title])
        _paq.push(['setCookieDomain', '*.drcv.de'])
        _paq.push(['setDomains', ['*.drcv.de']])
        _paq.push(['trackPageView'])
        _paq.push(['enableLinkTracking'])
        ;(function() {
            var u = '//piwik.dedx.de/'
            _paq.push(['setTrackerUrl', u + 'piwik.php'])
            _paq.push(['setSiteId', '1'])
            var d = document,
                g = d.createElement('script'),
                s = d.getElementsByTagName('script')[0]
            g.type = 'text/javascript'
            g.async = true
            g.defer = true
            g.src = u + 'piwik.js'
            s.parentNode.insertBefore(g, s)
        })()
    }
}
</script>

