<template>
  <v-footer color="#b71c1c;"
            dark
            padless>
    <v-row justify="center"
           no-gutters>

      <v-btn color="white"
             text
             tile
             class="my-2"
             disabled>
        {{ new Date().getFullYear() }} &copy; <strong>DRCV</strong>
      </v-btn>

      <v-btn color="white"
             text
             tile
             class="my-2"
             href="https://motorsport.adac-weser-ems.de/">
        <v-img height="40px"
               width="200px"
               href="https://motorsport.adac-weser-ems.de/"
               src="https://drcv.de/storage/Logo/OC_ADAC_Wem_quer_klein.png">
        </v-img>
      </v-btn>

      <v-btn color="white"
             v-for="(item, i) in items"
             :key="i"
             text
             tile
             class="my-2"
             :href="item.link">
        {{ item.name }}
      </v-btn>

    </v-row>
  </v-footer>

</template>

<script>
export default {
    name: 'CFooter',
    data: function() {
        return {
            items: [
                { name: 'Startseite', link: '/' },
                { name: 'Über uns', link: '/drcv' },
                { name: 'Impressum', link: '/impressum' }
            ]
        }
    }
}
</script>
